import React, { useEffect, useRef, useState } from "react";
// import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Tabs,
  Button,
  // Modal,
  message,
  // Menu,
  // DatePicker,
  Alert,
  Space,
  Result,
  // Tooltip,
} from "antd";
// import { Input } from "formik-antd";
import { Formik } from "formik";
import {
  CheckCircleOutlined,
  UndoOutlined,
  //   CloseCircleOutlined,
  //   HistoryOutlined,
  //   FieldTimeOutlined,
  //   StopOutlined,
  //   CheckOutlined,
} from "@ant-design/icons";
import {
  fetchEntityActivities,
  createActivity,
  createRequestActivity,
  removeActivity,
} from "../../../redux/action/activityAction";
import { sendCancelShoot } from "../../../redux/action/notifications";
import {
  formatUsAddress,
  hasUnpaidInvoice,
  hasDeferredTerms,
} from "../../../shared/utils/utils";
import Loading from "../../../shared/loading/Loading.js";
import "./shootTab.css";
import Details from "./Details/Details";
import {
  fetchShootById,
  updateShoot,
  rescheduleShoot,
} from "../../../redux/action/shootAction";
import {
  fetchInvoiceByShootId,
  updateInvoice,
  createInvoice,
  fetchInvoiceById,
} from "../../../redux/action/invoiceAction";
import { fetchBrokerages } from "../../../redux/action/brokerageAction";
import { updateMedia, createMedia } from "../../../redux/action/mediaAction";
import { fetchOfferings } from "../../../redux/action/offeringAction";
import { createCredit } from "../../../redux/action/creditAction";
import { fetchClient } from "../../../redux/action/clientAction";
import { fetchAccessNotes } from "../../../redux/action/note";
import {
  fetchPhotographerById,
  fetchPhotographers,
  fetchAllPhotoGraphersUnAvailabilityForADay,
} from "../../../redux/action/photographerAction";
import { fetchPaymentsByInvoiceId } from "../../../redux/action/paymentAction";

import Flyer from "./Flyer/Flyer";

import { useHistory } from "react-router";
import VirtualTour from "./VirtualTour/VirtualTour";
import Dollhouse from "./Dollhouse";
import ability from "../../../user/Component/Auth/ability";
import { SELECT_PHOTOGRAPHER } from "../../../redux/action/types";
import ProgressChecklist from "../../Components/Order/Common/ProgressChecklist";
import DuplicateButton from "./Details/DuplicateShoot";
import RescheduleButton from "./Details/RescheduleShoot";
import ShareShootButton from "../../../shared/Components/ShareShootButton";
import ShootTypeBadge from "../../../shared/Components/ShootTypeBadge";

const { TabPane } = Tabs;
// const { confirm } = Modal;
// const { TextArea } = Input;

const ShootTab = ({
  match,
  fetchBrokerages,
  fetchAccessNotes,
  shoot: { selectedShoot, isLoading },
  brokerage: { brokerages },
  activity: { activities },
  note: { accessNotes },
  photographer,
  media,
  invoice,
  fetchPhotographers,
  fetchInvoiceByShootId,
  createCredit,
  fetchPhotographerById,
  fetchOfferings,
  updateInvoice,
  createInvoice,
  fetchInvoiceById,
  //  updateNote,
  fetchShootById,
  fetchClient,
  // invoice: { selectedInvoice },
  fetchEntityActivities,
  createActivity,
  createRequestActivity,
  updateShoot,
  rescheduleShoot,
  updateMedia,
  createMedia,
  //  sendCancelShoot,
  auth,
  setSelectedPhotographer,
  offering: { offerings },
  fetchPaymentsByInvoiceId,
  payments: { invoicePayments },
  client: { selectedClient },
  allPhotographerUnavailSlotsForDay,
  fetchAllPhotoGraphersUnAvailabilityForADay,
}) => {
  const history = useHistory();
  const { pathname, search } = history.location;

  const [activeKey, setActiveKey] = useState("details");
  const [items, setItems] = useState([
    { label: "Details", children: <Details />, key: "details" },
  ]);


  const newTabIndex = useRef(0);

  useEffect(() => {
    const getData = async () => {
      const shoot = await fetchShootById(match.params.id);
      if (shoot) {
        fetchEntityActivities(shoot?.data?._id, auth?.user?.access);
        fetchInvoiceByShootId(shoot?.data?._id);
        fetchClient(shoot?.data?.client_id);
        fetchAccessNotes(shoot?.data?.client_id, "EDITOR");
      } else {
        message.error("Shoot not found");
        history.push("/admin/shoots");
      }
      if (!brokerages || !brokerages.length) {
        fetchBrokerages();
      }
      if (shoot?.data?.events?.photographer) {
        fetchPhotographerById(shoot?.data?.events?.photographer?._id);
      }
      if (!photographer.photographers.length) {
        fetchPhotographers();
      }
      if (!offerings?.length) {
        fetchOfferings();
      }
      denyMediaAccess(shoot?.data);
    };
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const denyMediaAccess = (foundShoot) => {
    if (
      hasUnpaidInvoice(foundShoot?.invoice) &&
      !hasDeferredTerms(selectedClient?.preferences?.billing?.terms)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const addVideoPackage = async () => {
    try {
      if (!selectedShoot || !selectedShoot.id) {
        message.error("No shoot selected.");
        return;
      }
  
      const shootId = selectedShoot.id;
      const response = await updateShoot(shootId, { video: { package: "Video Package" } });
      console.log("API Response:", response); 
      if (response.status === 200) {
        const newItems = [
          ...items,
          {
            label: "Video",
            children: <VirtualTour />,
            key: "tour-video",
          },
        ];
        setItems(newItems);
        console.log("Updated Items:", newItems); 
        setActiveKey("tour-video");
        message.success("Video package added successfully!");
      } else {
        message.error("Failed to add video package.");
      }
    } catch (error) {
      console.error("Error adding video package:", error);
      message.error("Failed to add video package.");
    }
  };

  const removeTab = (targetKey) => {
    const targetIndex = items.findIndex((pane) => pane.key === targetKey);
    const newPanes = items.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      setActiveKey(key);
    }
    setItems(newPanes);
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      addVideoPackage();
    } else {
      removeTab(targetKey);
    }
  };

  useEffect(() => {
    if (activeKey === "tour-video") {
      //
    }
  }, [activeKey]);

  const sendChangeRequestEmail = (values) => {
    axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notifications/change-request`,
      {
        ...values,
        street_address: selectedShoot?.address?.street,
        requester_email: auth?.user?.email,
        requester_name: auth?.user?.name,
      }
    );
  };

  const handleComplete = async () => {
    // Parse through checklist and mark all elements as complete
    let list = selectedShoot.checklist;
    for (let key in list) {
      list[key] = true;
    }

    const res = await updateShoot(selectedShoot._id, {
      status: "Completed",
      checklist: list,
    });

    if (res.status === 200) {
      // Create activity
      createActivity(selectedShoot, "Shoot marked as Complete");
      message.success("Shoot marked as Complete");
    }
  };

  const handleUndo = async () => {
    const res = await updateShoot(selectedShoot._id, {
      status: "Processing",
      checklist: { ...selectedShoot.checklist, photos_delivered: false },
    });

    if (res.status === 200) {
      // Create activity
      createActivity(selectedShoot, "Shoot marked as Processing");
      message.success("Shoot marked as Processing");
    }
  };

  if (
    auth?.user?.access.includes("CLIENT") &&
    !selectedClient?.preferences?.media?.full_access &&
    selectedShoot?.archived
  ) {
    return (
      <Result
        title="Sorry, your access has expired"
        subTitle="The rights to all deliverables are active for one year post purchase date; all deliverables must be repurchased after one year."
        extra={
          <a href="/admin">
            {" "}
            <Button type="primary">Return to your dashboard</Button>
          </a>
        }
      />
    );
  }

  // const sendDenyRequestEmail = (data) => {
  //   axios.post(`${process.env.REACT_APP_ROOT_URL}/notifications/deny-request`, {
  //     ...data,
  //     street_address: data?.address?.street,
  //     requester_email: data?.status_change_request?.requester?.email,
  //     requester_name: data?.status_change_request?.requester?.name,
  //     denied_reason: data?.status_change_request?.denied_reason,
  //   });
  // };

  const renderRequestForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          current_status: selectedShoot?.status,
          // new_status: newStatus,
          new_date: "",
          current_date: selectedShoot?.events?.start,
          requester: auth?.user?._id,
          _request_created_at: new Date(),
          admin_approved: false,
          resolved: false,
        }}
        onSubmit={async (values) => {
          const activityData = {
            user: auth?.user?._id,
            entity_id: selectedShoot?._id,
          };
          // if (newStatus === "Deny") {
          //   const requestData = {
          //     status: "Pending",
          //     status_change_request: {
          //       ...selectedShoot.status_change_request,
          //       admin_approved: false,
          //       resolved: true,
          //       denied_reason: values?.denied_reason,
          //       approver: auth?.user?._id,
          //     },
          //   };
          //   const res = await updateShoot(selectedShoot._id, requestData);
          //   if (res.status === 200) {
          //     sendDenyRequestEmail(requestData);
          //     createRequestActivity(
          //       activityData,
          //       `Request to change the shoot was declined by ${auth?.user?.name}. Reason: ${values?.denied_reason}`
          //     );
          //   }
          // } else {
          const res = await updateShoot(selectedShoot._id, {
            status_change_request: values,
          });
          if (res.status === 200) {
            // Create an Activity
            createRequestActivity(
              activityData,
              `Request made to ${values.new_status} the shoot. Reason: ${values?.request_reason}`
            );
            message.success("Your request was sent successfully.");

            if (auth?.user?.access.includes("CLIENT")) {
              // Send notification email
              await sendChangeRequestEmail(values);
            }
          }
          // }
          // setModalVisible(false);
        }}
        render={() => (
          // { handleSubmit, setFieldValue }
          <div className="reason">
            {/* <Modal
              title={`Request Shoot ${newStatus}`}
              visible={modalVisible}
              onOk={handleSubmit}
              onCancel={handleCancel}
            >
              {newStatus === "Reschedule" && (
                <div className="mb-4 form-group">
                  <label>Select your preferred date (optional)</label>
                  <DatePicker
                    disabledDate={(date) => {
                      return date.day() === 0 ? true : false;
                    }}
                    onChange={(value) =>
                      setFieldValue("new_date", moment(value).format())
                    }
                  />
                </div>
              )}
              {(newStatus === "Reschedule" || newStatus === "Postpone") && (
                <div className="form-group">
                  <label>What is the reason for the change? (required)</label>
                  <TextArea name="request_reason" rows={2} />
                </div>
              )}
              {newStatus === "Deny" && (
                <div className="form-group">
                  <label>
                    What is the reason for the denying the request? (required)
                  </label>
                  <TextArea name="denied_reason" rows={2} />
                </div>
              )}
            </Modal> */}
          </div>
        )}
      />
    );
  };

  // const handleMenuClick = (e) => {
  //   console.log("click", e);
  // };

  // const buttonMenu = (
  //   <Menu onClick={handleMenuClick}>
  //     {selectedShoot && selectedShoot.status !== "Reschedule" && (
  //       <Menu.Item
  //         onClick={() => handleModalOpen("Reschedule")}
  //         key="1"
  //         icon={<HistoryOutlined />}
  //       >
  //         Reschedule Shoot
  //       </Menu.Item>
  //     )}
  //     {selectedShoot && selectedShoot.status !== "Postpone" && (
  //       <Menu.Item
  //         onClick={() => handleModalOpen("Postpone")}
  //         key="2"
  //         icon={<FieldTimeOutlined />}
  //       >
  //         Postpone Shoot
  //       </Menu.Item>
  //     )}
  //     <Menu.Divider />

  //     <Menu.Item
  //       onClick={() => onConfirmCancel(selectedShoot)}
  //       key="3"
  //       icon={<CloseCircleOutlined />}
  //       danger
  //       disabled={
  //         (selectedShoot && selectedShoot.status === "Cancelled") ||
  //         (selectedShoot && selectedShoot.status === "Completed") ||
  //         (selectedShoot && selectedShoot.status === "Processing")
  //       }
  //     >
  //       Cancel Shoot
  //     </Menu.Item>
  //   </Menu>
  // );

  // const renderPendingAlert = (shoot) => {
  //   return (
  //     <Alert
  //       message={
  //         <div>
  //           <Row>
  //             <Col span={20}>
  //               There is a pending request to{" "}
  //               {shoot.status_change_request.new_status} this shoot.{" "}
  //               {shoot.status_change_request.new_date &&
  //                 `Requested date is
  //           ${moment(shoot.status_change_request.new_date).format(
  //             "MMM D, YYYY"
  //           )}. `}
  //               <strong>Reason:</strong>{" "}
  //               <em>{shoot.status_change_request.request_reason}</em>
  //             </Col>
  //             <Col span={4}>
  //               {ability.can("deny", "Request") && (
  //                 <Tooltip placement="top" title="Deny">
  //                   <Button
  //                     className="mr-3 float-right"
  //                     style={{ color: "red" }}
  //                     icon={<StopOutlined />}
  //                     onClick={() => handleModalOpen("Deny")}
  //                   />
  //                 </Tooltip>
  //               )}
  //               {ability.can("approve", "Request") && (
  //                 <Tooltip placement="top" title="Approve">
  //                   <Button
  //                     className="mr-2 ml-2 float-right"
  //                     style={{ color: "green" }}
  //                     icon={<CheckOutlined />}
  //                     onClick={() => handleApproveRequest(shoot)}
  //                   />
  //                 </Tooltip>
  //               )}
  //             </Col>
  //           </Row>
  //         </div>
  //       }
  //       banner
  //     />
  //   );
  // };

  // const renderRescheduleAlert = (shoot) => {
  //   return (
  //     <Alert
  //       message={
  //         <div>
  //           <Row>
  //             <Col span={20}>
  //               Proposed reschedule date is{" "}
  //               {moment(shoot.status_change_request.new_date).format(
  //                 "MMM D, YYYY"
  //               )}
  //               . <strong>Comment:</strong>{" "}
  //               <em>{shoot.status_change_request.denied_reason}</em>
  //             </Col>
  //             <Col span={4}>
  //               {ability.can("reject", "Request") && (
  //                 <Tooltip placement="top" title="Reject">
  //                   <Button
  //                     className="mr-3 float-right"
  //                     style={{ color: "red" }}
  //                     icon={<StopOutlined />}
  //                     onClick={() => handleModalOpen("Deny")}
  //                   />
  //                 </Tooltip>
  //               )}
  //               {ability.can("accept", "Request") && (
  //                 <Tooltip placement="top" title="Approve">
  //                   <Button
  //                     className="mr-2 ml-2 float-right"
  //                     style={{ color: "green" }}
  //                     icon={<CheckOutlined />}
  //                     onClick={() => handleApproveRequest(shoot)}
  //                   />
  //                 </Tooltip>
  //               )}
  //             </Col>
  //           </Row>
  //         </div>
  //       }
  //       type="success"
  //       banner
  //     />
  //   );
  // };

  // const renderDeniedAlert = (shoot) => {
  //   return (
  //     <Alert
  //       message={
  //         <div>
  //           <Row>
  //             <Col span={24}>
  //               Request denied to {shoot.status_change_request.new_status} this
  //               shoot.{" "}
  //               {shoot.status_change_request.new_date &&
  //                 `Requested date is
  //           ${moment(shoot.status_change_request.new_date).format(
  //             "MMM D, YYYY"
  //           )}. `}
  //               <strong>Reason:</strong>{" "}
  //               <em>{shoot.status_change_request.denied_reason}</em>
  //             </Col>
  //           </Row>
  //         </div>
  //       }
  //       type="error"
  //       banner
  //     />
  //   );
  // };

  // const handleApproveRequest = async (shoot) => {
  //   const res = await updateShoot(shoot._id, {
  //     status: shoot.status_change_request.new_status,
  //     status_change_request: {
  //       ...shoot.status_change_request,
  //       admin_approved: true,
  //       client_approved: true,
  //       resolved: true,
  //       approver: auth?.user?._id,
  //     },
  //   });

  //   if (res.status === 200) {
  //     message.success("Request approved!");
  //     const activityData = {
  //       user: auth?.user?._id,
  //       entity_id: shoot?._id,
  //     };
  //     createRequestActivity(
  //       activityData,
  //       `Request to ${
  //         shoot.status_change_request.new_status
  //           ? shoot.status_change_request.new_status
  //           : "change"
  //       } the Shoot approved by ${auth?.user?.name}.`
  //     );
  //   } else {
  //     message.error("Oops! Something went wrong.");
  //   }
  // };

  // const handleDenyRequest = (shoot) => {}

  const tabChangeHandler = (tab) => {
    history.push({
      pathname,
      search: `?tab=${tab}`,
    });
  };

  return (
    <div>
      {isLoading && (
        <div className="loading-container">
          <Loading />
        </div>
      )}
      {!isLoading && selectedShoot && (
        <React.Fragment>
          {accessNotes?.count > 0 && auth?.user?.access.includes("EDITOR") && (
            <Alert
              message={`You have ${accessNotes?.count} important note${
                accessNotes?.count === 1 ? "" : "s"
              } to review`}
              type="warning"
              showIcon
              closable
            />
          )}
          {!auth?.isAuthenticated && (
            <Alert
              message="You have limited access. Please sign in to have full access."
              banner
              action={
                <Space>
                  <a href="/admin/login">
                    <Button size="small" type="primary">
                      Sign In
                    </Button>
                  </a>
                </Space>
              }
            />
          )}

          {/* {selectedShoot.status_change_request &&
            !selectedShoot.status_change_request.resolved &&
            !selectedShoot.status_change_request.client_approved &&
            renderPendingAlert(selectedShoot)}

          {selectedShoot.status_change_request &&
            selectedShoot.status_change_request.resolved &&
            !selectedShoot.status_change_request.admin_approved &&
            renderDeniedAlert(selectedShoot)}

          {selectedShoot.status_change_request &&
            !selectedShoot.status_change_request.client_approved &&
            selectedShoot.status_change_request.admin_approved &&
            renderRescheduleAlert(selectedShoot)} */}

          <div style={{ margin: "0 10px" }}>
            <Row justify="space-between" style={{ margin: "10px" }}>
              <Col>
                {" "}
                <h3 className="mt-3">
                  <span className="mr-1">
                    {selectedShoot?.booking_form?.shoot_type ===
                      "Residential" && (
                      <ShootTypeBadge
                        shootType="Residential"
                        bgColor="#a155b9"
                      />
                    )}
                    {selectedShoot?.booking_form?.shoot_type ===
                      "Multi-family" && (
                      <ShootTypeBadge
                        shootType="Multi-Family"
                        bgColor="#f765a3"
                      />
                    )}
                    {selectedShoot?.booking_form?.shoot_type ===
                      "Commercial" && (
                      <ShootTypeBadge
                        shootType="Commercial"
                        bgColor="#ffa4b6"
                      />
                    )}
                    {selectedShoot?.booking_form?.shoot_type ===
                      "Additional" && (
                      <ShootTypeBadge
                        shootType="Additional"
                        bgColor="#165baa"
                      />
                    )}
                  </span>
                  {formatUsAddress(
                    selectedShoot.address.full,
                    selectedShoot.address.street,
                    selectedShoot.address.apt,
                    selectedShoot.address.city,
                    selectedShoot.address.state,
                    selectedShoot.address.zip
                  )}{" "}
                  {(auth?.user?.access?.includes("ADMIN") ||
                    auth?.user?.access?.includes("COMMS")) && (
                    <>
                      <DuplicateButton originalShoot={selectedShoot} />
                      <RescheduleButton
                        originalShoot={selectedShoot}
                        createActivity={createActivity}
                        rescheduleShoot={rescheduleShoot}
                        createInvoice={createInvoice}
                        createMedia={createMedia}
                        updateInvoice={updateInvoice}
                        updateShoot={updateShoot}
                        user={auth?.user}
                      />
                    </>
                  )}
                </h3>
              </Col>

              <Col>
                <ProgressChecklist
                  shootId={selectedShoot._id}
                  checklist={selectedShoot.checklist}
                  updateShoot={updateShoot}
                />
              </Col>

              <ShareShootButton selectedShoot={selectedShoot} />
            </Row>
            <Row>
              <Col span={24}>
                <div className="card-container">
                  <Tabs
                    size="large"
                    type="card"
                    style={{ margin: "0 10px" }}
                    defaultActiveKey={search.replace("? tab=","")}
                    activeKey={activeKey}
                    onChange={(key) => {
                      setActiveKey(key);
                      tabChangeHandler(key);
                    }}
                    tabBarExtraContent={
                      <Space>
                        {(!selectedShoot.video?.package ||
                          selectedShoot.video.package === "") && (
                          <Button onClick={addVideoPackage}>Add Video Tab</Button>
                        )}

                        {ability.can("complete", "Shoots") &&
                          (selectedShoot.status === "Completed" ? (
                            <Button
                             danger
                              onClick={()=>handleUndo()} 
                              className="mb-1"
                              >
                              <UndoOutlined /> Mark as Processing
                            </Button>
                          ) : (
                            <Button
                             type="primary"
                              onClick={() =>
                                handleComplete()} 
                              className="mb-1">
                              <CheckCircleOutlined /> Mark as Complete
                            </Button>
                          ))}
                      </Space>
                    }
                  >
                    <TabPane
                      tab="Details"
                      key="details"
                      style={{ padding: "20px" }}
                    >
                      <Details
                        allPhotographerUnavailSlotsForDay={
                          allPhotographerUnavailSlotsForDay
                        }
                        fetchAllPhotoGraphersUnAvailabilityForADay={
                          fetchAllPhotoGraphersUnAvailabilityForADay
                        }
                        update={updateShoot}
                        updateInvoice={updateInvoice}
                        createCredit={createCredit}
                        createActivity={createActivity}
                        selectedShoot={selectedShoot}
                        activities={activities}
                        invoice={invoice}
                        auth={auth}
                        photographer={photographer}
                        setSelectedPhotographer={setSelectedPhotographer}
                        offerings={offerings}
                        fetchShootById={fetchShootById}
                        fetchInvoiceById={fetchInvoiceById}
                        fetchPaymentsByInvoiceId={fetchPaymentsByInvoiceId}
                        payments={invoicePayments}
                        match={match}
                      />
                      {renderRequestForm()}
                    </TabPane>
                    <TabPane tab={<span>Photos</span>} key="tour">
                      <VirtualTour
                        updateMedia={updateMedia}
                        updateShoot={updateShoot}
                        media={media}
                        auth={auth}
                        brokerages={brokerages}
                        selectedShoot={selectedShoot}
                        createActivity={(shoot, text) =>
                          createActivity(shoot, text)
                        }
                        denyMediaAccess={
                          hasUnpaidInvoice(selectedShoot?.invoice) &&
                          !hasDeferredTerms(
                            selectedClient?.preferences?.billing?.terms
                          ) &&
                          (!auth?.isAuthenticated ||
                            auth?.user?.access.includes("CLIENT"))
                        }
                      />
                    </TabPane>
                    {ability.can("manage", "Amenity") &&
                    selectedShoot?.reqs.includes("SP") ? (
                      <TabPane tab={<span>Amenity</span>} key="amenity">
                        <VirtualTour
                          updateMedia={updateMedia}
                          updateShoot={updateShoot}
                          media={media}
                          auth={auth}
                          brokerages={brokerages}
                          selectedShoot={selectedShoot}
                          createActivity={(shoot, text) =>
                            createActivity(shoot, text)
                          }
                          denyMediaAccess={
                            hasUnpaidInvoice(selectedShoot?.invoice) &&
                            !hasDeferredTerms(
                              selectedClient?.preferences?.billing?.terms
                            ) &&
                            (!auth?.isAuthenticated ||
                              auth?.user?.access.includes("CLIENT"))
                          }
                        />
                      </TabPane>
                    ) : null}
                    {selectedShoot?.video?.package &&
                      (selectedShoot?.video?.package !== "Social Media Video" ||
                        selectedShoot?.video?.package !== "") && (
                        <TabPane tab={<span>Video</span>} key="tour-video">
                          <VirtualTour
                            updateMedia={updateMedia}
                            updateShoot={updateShoot}
                            media={media}
                            auth={auth}
                            brokerages={brokerages}
                            selectedShoot={selectedShoot}
                            createActivity={(shoot, text) =>
                              createActivity(shoot, text)
                            }
                            denyMediaAccess={
                              hasUnpaidInvoice(selectedShoot?.invoice) &&
                              !hasDeferredTerms(
                                selectedClient?.preferences?.billing?.terms
                              ) &&
                              (!auth?.isAuthenticated ||
                                auth?.user?.access.includes("CLIENT"))
                            }
                          />
                        </TabPane>
                      )}
                    {ability.can("manage", "Flyer") &&
                      selectedShoot.reqs.includes("F") && (
                        <TabPane tab="Flyer" key="flyer">
                          <Flyer id={selectedShoot?._id} />
                        </TabPane>
                      )}
                    {(selectedShoot.reqs.includes("VR") ||
                      selectedShoot.reqs.includes("CVR") ||
                      selectedShoot.reqs.includes("MVR") ||
                      selectedShoot.reqs.includes("AVR")) && (
                      <TabPane tab="Dollhouse" key="dollhouse">
                        {Object.keys(media).length && (
                          <Dollhouse
                            updateMedia={updateMedia}
                            media={media}
                            selectedShoot={selectedShoot}
                            createActivity={(shoot, text) =>
                              createActivity(shoot, text)
                            }
                            denyMediaAccess={
                              hasUnpaidInvoice(selectedShoot?.invoice) &&
                              !hasDeferredTerms(
                                selectedClient?.preferences?.billing?.terms
                              ) &&
                              (!auth?.isAuthenticated ||
                                auth?.user?.access.includes("CLIENT"))
                            }
                          />
                        )}
                      </TabPane>
                    )}
                    {selectedShoot.reqs.includes("YT") && (
                      <TabPane tab={<span>Slideshow</span>} key="Youtube">
                        {Object.keys(media).length && (
                          <VirtualTour
                            updateMedia={updateMedia}
                            updateShoot={updateShoot}
                            media={media}
                            auth={auth}
                            brokerages={brokerages}
                            selectedShoot={selectedShoot}
                            createActivity={(shoot, text) =>
                              createActivity(shoot, text)
                            }
                            denyMediaAccess={
                              hasUnpaidInvoice(selectedShoot?.invoice) &&
                              !hasDeferredTerms(
                                selectedClient?.preferences?.billing?.terms
                              ) &&
                              (!auth?.isAuthenticated ||
                                auth?.user?.access.includes("CLIENT"))
                            }
                          />
                          // <ShootVideo
                          //   updateMedia={updateMedia}
                          //   media={media}
                          //   shoot={selectedShoot}
                          //   createActivity={(shoot, text) =>
                          //     createActivity(shoot, text)
                          //   }
                          // />
                        )}
                      </TabPane>
                    )}
                    {(ability.can("edit", "Social") ||
                      ability.can("read", "Social")) &&
                      (selectedShoot.reqs.includes("SMV") ||
                        selectedShoot.reqs.includes("SMV30") ||
                        selectedShoot.reqs.includes("CSMV") ||
                        selectedShoot.reqs.includes("MSMV") ||
                        selectedShoot.reqs.includes("ASMV")) && (
                        <TabPane tab="Social Media" key="socialMedia">
                          <VirtualTour
                            updateMedia={updateMedia}
                            updateShoot={updateShoot}
                            media={media}
                            auth={auth}
                            brokerages={brokerages}
                            selectedShoot={selectedShoot}
                            createActivity={(shoot, text) =>
                              createActivity(shoot, text)
                            }
                            denyMediaAccess={
                              hasUnpaidInvoice(selectedShoot?.invoice) &&
                              !hasDeferredTerms(
                                selectedClient?.preferences?.billing?.terms
                              ) &&
                              (!auth?.isAuthenticated ||
                                auth?.user?.access.includes("SOCIAL"))
                            }
                          />{" "}
                        </TabPane>
                      )}
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    shoot: state.shoots,
    brokerage: state.brokerages,
    media: state.media.media ? state.media.media : {},
    invoice: state.invoice,
    activity: state.activity,
    auth: state.auth,
    photographer: state.photographer,
    offering: state.offering,
    payments: state.payments,
    note: state.note,
    client: state.clients,
    allPhotographerUnavailSlotsForDay:
      state.photographer?.allPhotographerUnavailSlotsForDay,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchShootById,
      fetchBrokerages,
      fetchEntityActivities,
      fetchAccessNotes,
      fetchInvoiceByShootId,
      fetchPhotographerById,
      fetchPhotographers,
      createActivity,
      createRequestActivity,
      removeActivity,
      updateShoot,
      rescheduleShoot,
      fetchOfferings,
      updateMedia,
      createMedia,
      updateInvoice,
      createCredit,
      createInvoice,
      sendCancelShoot,
      fetchInvoiceById,
      fetchPaymentsByInvoiceId,
      fetchClient,
      fetchAllPhotoGraphersUnAvailabilityForADay,
      setSelectedPhotographer: (phtg) => {
        return { type: SELECT_PHOTOGRAPHER, payload: phtg };
      },
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ShootTab);
