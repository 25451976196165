import React from "react";
import { Row, Col, Tag } from "antd";
import moment from "moment";
import ReactGA from "react-ga";
import slugify from "slugify";

import { ACTIVITIES_CLIENTS, STATUS_COLOR } from "../../utils/settings";

export const getDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
};

export const getTime = (timestamp) => {
  const date = new Date(timestamp);
  return date.getHours() + ":" + date.getMinutes();
};

export const concateDateTime = (date, time) => {
  const dateTime = new Date(date);
  time = time.split(":");
  dateTime.setHours(time[0]);
  dateTime.setMinutes(time[1]);
  console.log(date, time, dateTime);
  return dateTime;
};

export const toSnakeCase = (str) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("_")
  );
};

export const toHyphenCase = (str) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("-")
  );
};

export const toTitleCase = (str) => {
  const sentence = str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join(" ")
    .split(" ");

  //const sentence = str.toLowerCase().replace(/_/g, " ").split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
};

export const sortPhotographer = (array) => {
  return array.sort(function (a, b) {
    var x = a.name.toLowerCase();
    var y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
};

export const roundedCurrency = (amount) => {
  return amount ? `$${amount.toFixed(2)}` : "$0.00";
};
export const roundOffNumber = (number, decimal = 2) => {
  let result =
    Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
  return result;
};

export const renderLabel = (key, value) => {
  return value && value !== "Invalid date" ? (
    <span>
      <strong>{`${key}:`}</strong> {value}
      <br />
    </span>
  ) : (
    ""
  );
};

export const renderSection = (icon, content) => {
  return (
    <div>
      <Row style={{ marginTop: "10px" }}>
        <Col span="2" style={{ fontSize: "1.3rem" }}>
          {icon}
        </Col>
        <Col span="22">{content}</Col>
      </Row>
    </div>
  );
};

export const renderBookingFormSection = (title, content, edit) => {
  return (
    <div>
      <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          {title}
        </Col>
        <Col
          style={{ paddingLeft: "30px" }}
          xs={24}
          sm={24}
          md={18}
          lg={18}
          xl={18}
        >
          {content}
        </Col>
        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
          {edit}
        </Col>
      </Row>
    </div>
  );
};

export const convertISODateString = (timestamp) => {
  const date = addZero(timestamp.getDate());
  const month = addZero(timestamp.getMonth() + 1);
  const year = timestamp.getFullYear();
  const hour = addZero(timestamp.getHours());
  const miniute = addZero(timestamp.getMinutes());
  const second = addZero(timestamp.getSeconds());
  return (
    year +
    "-" +
    month +
    "-" +
    date +
    "T" +
    hour +
    ":" +
    miniute +
    ":" +
    second +
    ".000Z"
  );
};

export const convertISOStrToDate = (ISODateStr) => {
  const Utc_date = new Date(ISODateStr);

  return new Date(
    Utc_date.getUTCFullYear(),
    Utc_date.getUTCMonth(),
    Utc_date.getUTCDate(),
    Utc_date.getUTCHours(),
    Utc_date.getUTCMinutes(),
    Utc_date.getUTCSeconds()
  );
};

export const convertDateToTime = (date) => {
  const hour = convert12HrFmt(date.getHours());
  const miniute = addZero(date.getMinutes());
  const session = date.getHours() < 12 ? "A" : "P";
  return hour + ":" + miniute + session;
};

export const convertTimeLine = (date) => {
  const fromDate = new Date().getTime();
  const toDate = convertISOStrToDate(date).getTime();
  const res = fromDate - toDate;
  const res_In_Days = Math.floor(res / (1000 * 3600 * 24));
  const res_In_Hours = Math.floor(res / (1000 * 3600));
  const res_In_min = Math.floor(res / (1000 * 60));
  if (res_In_Days > 60) {
    return `${Math.floor(res_In_Days / 30)} months ago`;
  } else if (res_In_Days > 30) {
    return `1 month ago`;
  } else if (res_In_Days === 1) {
    return `1 day ago`;
  } else if (res_In_Days > 0 && res_In_Days < 30) {
    return `${res_In_Days} days ago`;
  } else if (res_In_Hours > 0) {
    return `${res_In_Hours} hr ago`;
  } else if (res_In_min > 0) {
    return `${res_In_min} m ago`;
  } else {
    return "less than a minute";
  }
};

export const eventDateFormat = (from, to) => {
  const date =
    moment.utc(from).format("MM/DD/YY") +
    " " +
    moment.utc(from).format("LT") +
    "-" +
    moment.utc(to).format("LT");
  return date;
};

const addZero = (number) => {
  if (number < 10) {
    return "0" + number;
  } else {
    return number;
  }
};

const convert12HrFmt = (hr) => {
  if (hr === 0) {
    return 12;
  } else if (hr > 12) {
    return hr - 12;
  } else {
    return hr;
  }
};
export const getShootColor = (status) => {
  return STATUS_COLOR[status];
};

export const getTagColor = (status) => {
  const statusColor = {
    "Not Available": "warning",
    Ready: "success",
    Processing: "processing",
  };
  return statusColor[status];
};

export const statusTagColor = (status) => {
  if (status === "Scheduled") {
    return "#1bb901";
  } else if (status === "Pending") {
    return "#c0ca33";
  } else if (status === "Reschedule") {
    return "#fb9795";
  } else if (status === "Tentative") {
    return "#00bcd4";
  } else if (status === "Completed") {
    return "#777777";
  } else if (status === "Cancelled") {
    return "volcano";
  } else if (status === "Processing") {
    return "gold";
  } else {
    return;
  }
};

export const formatUsAddress = (full, street, apt, city, state, zip) => {
  return street
    ? `${street?.replace("null ", "") || ""}, ${apt ? `#${apt}, ` : ""} ${
        city || ""
      }, ${state || ""} ${zip?.substring(0, 5) || ""}`
    : full;
};

export const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const filterActivities = (entryPoint, activities) => {
  if (entryPoint?.includes("CLIENT")) {
    const filter = activities.filter((acty) => {
      for (let i = 0; i < ACTIVITIES_CLIENTS.length; i++) {
        if (acty.text.indexOf(ACTIVITIES_CLIENTS[i]) !== -1) {
          return true;
        }
      }
      return false;
    });
    return filter;
  } else {
    return activities;
  }
};

export const calculateTax = (price) => {
  const calc = (price / 100) * 8.25;
  const value = parseFloat(calc.toFixed(2));
  return value;
};

export const parseServiceCodes = (services) => {
  return services
    ?.filter((s) => s.code !== "FREE")
    .map((service) => {
      console.log(service.code);
      if (service.code === "T") {
        return <Tag color="gold">Twilight</Tag>;
      } else if (service.code === "4H!") {
        return <Tag color="#f50">{service.code}</Tag>;
      } else if (service.code === "SD") {
        return <Tag color="#87d068">{service.code}</Tag>;
      } else if (service.code === "1D12!") {
        return <Tag color="red">{service.code}</Tag>;
      } else if (service.code === "1D9") {
        return <Tag color="green">{service.code}</Tag>;
      } else if (service.code === "A") {
        return <Tag color="#108ee9">Aerial</Tag>;
      } else if (service.code === "4HR") {
        return <Tag color="blue">{service.code}</Tag>;
      } else {
        return <Tag>{service.code}</Tag>;
      }
    });
};

export const trackGaEvent = (category, action) => {
  ReactGA.event({
    category,
    action,
  });
};

export const hasUnpaidInvoice = (invoices) => {
  return invoices?.every((inv) => inv.paid === false);
};

export const hasDeferredTerms = (terms) => {
  return terms === "Deferred" ? true : false;
};

export const verifyAddress = (shoot) => {
  const street1 = shoot?.address?.street?.replace("null ", "");
  const street2 = shoot?.booking_form?.street?.replace("null ", "");
  const foundStreet1 = street1?.split(" ")[0];
  const foundStreet2 = street2?.split(" ")[0];

  return (
    (foundStreet1 !== foundStreet2 ||
      shoot?.address?.city !== shoot?.booking_form?.city) &&
    shoot.status !== "Completed" &&
    !shoot?.address?.verified
  );
};

export const renderIconLine = (IconType, text) => {
  return text ? (
    <Row className="m-2" wrap={false}>
      <Col flex="none">
        {" "}
        <IconType twoToneColor="#1079C6" className="mr-2" />
      </Col>
      <Col flex="auto">{text}</Col>

      <br />
    </Row>
  ) : null;
};

export const setOpacity = (status, color) => {
  if (status !== "Scheduled") {
    return `${color}64`;
  } else {
    return color;
  }
};

export const generateRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result.toUpperCase();
};

export const slugifyString = (val, replaceWith) => {
  const valText = val ? val : "No Address";
  return slugify(valText, {
    replacement: replaceWith,
    remove: /[$*_+~.()'"!\-:@,#]/g,
    lower: true,
    trim: true,
  });
};

export const formatTelForSms = (telNum) => {
  let formatted = telNum.replace(/\D/g, "");
  if (formatted.length === 10) {
    return `+1${formatted}`;
  } else if (formatted.length === 11) {
    return `+${formatted}`;
  } else {
    return formatted;
  }
};
export const calculateVRPrice = (sqftFootage) => {
  const calculatedPrice =
    (((47 * (30 + (sqftFootage / 1000) * 35)) / 60 +
      49 +
      (sqftFootage / 250) * 5) /
      sqftFootage) *
    sqftFootage;
  return calculatedPrice;
};
